<template>
  <a-card title="Impor Produk Baru" :bordered="false">
    <a-radio-group
      v-model="isVariant"
      button-style="solid"
      class="product-variant mb-4"
      :disabled="downloadLoading || uploadLoading"
      @change="changeVariant"
    >
      <a-radio-button :value="false">
        Non Variant
      </a-radio-button>
      <a-radio-button :value="true">
        Multi Variant
      </a-radio-button>
    </a-radio-group>
    <p class="mt-4">
      Cara mudah untuk tambah banyak produk sekaligus.
    </p>
    <a-card style="width: 75%">
      <div class="mb-4">
        <a-row :gutter="16">
          <a-col :span="16">
            <h6 style="font-weight: bold">
              Download Template
            </h6>
            <p class="mb-0">
              File ini memiliki data yang diperlukan Powerbiz untuk mengimpor data Anda dengan benar.
            </p>
          </a-col>
          <a-col :span="8">
            <a-button
              type="primary"
              class="mt-4"
              style="width: 200px"
              :loading="downloadLoading"
              :disabled="uploadLoading"
              @click="downloadTemplate"
            >
              <a-icon v-if="!downloadLoading" type="download" />
              Download Template
            </a-button>
          </a-col>
        </a-row>
      </div>
      <hr />
      <div class="mt-4">
        <a-row :gutter="16">
          <a-col :span="16">
            <h6 style="font-weight: bold">
              Upload Template
            </h6>
            <p class="mb-0">
              Setelah selesai mengisi template, silahkan Unggah file. File yang Anda unggah harus dalam format xlsx atau xls.
            </p>
          </a-col>
          <a-col :span="8">
            <a-button
              v-if="fileList.length"
              type="primary"
              class="mt-4"
              style="width: 200px"
              :loading="uploadLoading"
              :disabled="downloadLoading"
              @click="uploadTemplate"
            >
              <a-icon v-if="!uploadLoading" type="upload" />
              Upload Template
            </a-button>
            <a-upload
              :file-list="fileList"
              :remove="handleRemove"
              :before-upload="beforeUpload"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            >
              <a-button
                v-if="!fileList.length"
                type="primary"
                class="mt-4"
                style="width: 200px; background-color: #fff !important; color: #E00000 !important"
                ghost
              >
                <a-icon type="upload" />
                Select File
              </a-button>
            </a-upload>
          </a-col>
        </a-row>
      </div>
    </a-card>
    <a-card v-if="isUpload" class="mt-4" style="width: 75%">
      <h6 class="mb-3" style="font-weight: bold">
        Impor Data
      </h6>
      <p v-if="!result.length && !isFailed && isUpload" class="mb-0">
        <a-icon type="check-circle" theme="filled" class="mr-2" style="color: green" />
        Selamat data berhasi diimpor. Silahkan cek hasilnya pada
        <router-link
          :to="{
            path: '/product',
            query: {
              workspace_id: $route.query.workspace_id,
              business_id: $route.query.business_id,
              warehouse_id: $route.query.warehouse_id,
            },
          }"
        >
          <b>{{ $t('product.listProduct') }}</b>
        </router-link>
      </p>
      <p v-if="!result.length && isFailed && isUpload" class="mb-0">
        <a-icon type="warning" theme="filled" class="mr-2" style="color: red" />
        Maaf. Proses impor data Anda gagal, semua data gagal diimpor. Mohon periksa kembali file yang Anda upload.
      </p>
      <p v-if="result.length && !isFailed && isUpload">
        <a-icon type="warning" theme="filled" class="mr-2" style="color: red" />
        Maaf. Proses impor data Anda gagal, terdapat <b style="color: red">{{ result.length }}</b> data yang gagal
      </p>
      <a-table
        v-if="result.length && !isFailed && isUpload"
        :columns="columns"
        :row-key="(record, index) => index"
        :data-source="result"
        :pagination="false"
        :loading="uploadLoading"
      />
    </a-card>
  </a-card>
</template>

<script>
import { downloadFile, uploadFile } from '@/api/product'

export default {
  name: 'ImportProductPage',
  data() {
    return {
      downloadLoading: false,
      uploadLoading: false,
      isUpload: false,
      isFailed: false,
      isVariant: false,
      fileList: [],
      result: [],
      columns: [
        {
          title: 'Baris',
          dataIndex: 'row',
          width: '20%',
        },
        {
          title: 'Keterangan',
          dataIndex: 'note',
          width: '80%',
        },
      ],
    }
  },
  computed: {
    businessId() {
      return this.$store.state.user.restriction_base === 'Warehouse'
        ? Object.keys(this.$store.getters['user/businessListObjectByKey'])[0]
        : this.$route.query.business_id
    },
  },
  methods: {
    async downloadTemplate() {
      this.downloadLoading = true

      await downloadFile({
        businessId: this.businessId,
        params: {
          warehouse_ids: this.$route.query.warehouse_id,
          is_variant: this.isVariant,
        },
      })
        .then(res => {
          if (res?.data) {
            const a = document.createElement('a')
            a.href = window.URL.createObjectURL(res.data)
            a.download = `template - ${!this.isVariant ? 'Non Variant' : 'Multi Variant'}.xlsx`
            document.body.appendChild(a)
            a.click()
            setTimeout(() => {
              a.remove()
            }, 9)
          }
        })
        .catch(err => console.error(err))
        .finally(() => this.downloadLoading = false)
    },
    async uploadTemplate() {
      this.isUpload = false
      this.uploadLoading = true
      const { workspace_id } = this.$route.query
      const formData = new FormData()
      formData.append('file', this.fileList[0])
      formData.append('is_variant', this.isVariant)

      await uploadFile({
        workspaceId: workspace_id,
        businessId: this.businessId,
        formData,
      })
        .then(({ data: { data: response } }) => {
          this.isFailed = false
          this.fileList = []
          this.result = response?.error_data_list || []
        })
        .catch(err => {
          this.isFailed = true
          this.result = []
          console.error(err)
        })
        .finally(() => {
          this.uploadLoading = false
          this.isUpload = true
        })
    },
    changeVariant() {
      this.resetResult()
      this.fileList = []
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file)
      const newFileList = this.fileList.slice()
      newFileList.splice(index, 1)
      this.fileList = newFileList
    },
    beforeUpload(file) {
      this.resetResult()
      this.fileList = [...this.fileList, file]
      return false
    },
    resetResult() {
      this.isUpload = false
      this.result = []
    },
  },
}
</script>

<style lang="scss">
.product-variant {
  .ant-radio-button-wrapper {
    margin-right: 15px;
    border: 1px solid #e4e9f0;
    border-radius: 25px !important;
    outline: none !important;
    box-shadow: none !important;

    &::before {
      content: none !important;
    }
  }
}
</style>